import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// TODO: Add default styles
import './index.scss';

import { Button } from 'components/atoms';

const getChildrenLabels = children => children.map(({ props }) => props.label);

const TabBar = ({ children, className, vertical, ...tabBarProps }) => {
  const [activeTab, setActiveTab] = useState(getChildrenLabels(children)[0]);

  const renderTabs = getChildrenLabels(children).map((navLabel, index) => (
    <Button
      onClick={() => {
        setActiveTab(navLabel);
      }}
      className={cn(
        `${className}__nav-btn`,
        {
          active: activeTab === navLabel && !className,
        },
        {
          [`${className}__nav-btn_active`]: activeTab === navLabel && className,
        }
      )}
      variant="inline"
      title={navLabel}
      key={index}
    />
  ));

  return (
    <div
      className={cn('tab-bar', { 'tab-bar_vertical': vertical }, [className])}
      {...tabBarProps}
    >
      <div className={cn('tab-bar__nav', `${className}__nav`)}>
        {renderTabs}
      </div>
      <div className={cn('tab-bar-container', `${className}-container`)}>
        {React.Children.map(children, child =>
          React.cloneElement(child, { activeTab })
        )}
      </div>
    </div>
  );
};

TabBar.defaultProps = {
  children: null,
  className: '',
  vertical: false,
};

TabBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  vertical: PropTypes.bool,
};

export default TabBar;
