import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import cn from 'classnames';
import Cookies from 'universal-cookie';
import get from 'lodash.get';

import './index.scss';

import { Text, Button, Link, TabBarItem } from 'components/atoms';
import TabBar from 'components/molecules/TabBar';

const CookiesPopup = ({ className }) => {
  const queryResult = useStaticQuery(
    graphql`
      query {
        allWordpressWpAppPopup(
          filter: { acf: { popup_id: { eq: "cookies_popup" } } }
        ) {
          edges {
            node {
              acf {
                blocks_app_popup {
                  title
                  description
                  footer_text
                  buttons {
                    accept_all_button_text
                    accept_only_required_button_text
                    hide_details_button_text
                    show_details_button_text
                  }
                  tabs {
                    title
                    content_type
                    content
                    subtabs {
                      title
                      content
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const popup = get(
    queryResult,
    'allWordpressWpAppPopup.edges[0].node.acf.blocks_app_popup[0]'
  );

  const [popupVisible, setPopupVisible] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);

  const hidePopup = () => {
    localStorage.setItem('cookie_consent_accepted', true);

    const cookies = new Cookies();
    cookies.set('cookie_consent_accepted', true, { path: '/' });

    setPopupVisible(false);
  };

  const showDetails = () => {
    setDetailsVisible(!detailsVisible);
  };

  let getCookieAccept = false;
  if (typeof window !== 'undefined') {
    getCookieAccept = localStorage.getItem('cookie_consent_accepted');
  }

  useEffect(() => {
    if (!getCookieAccept !== popupVisible) {
      setPopupVisible(!getCookieAccept);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!popup) {
    return null;
  }

  return (
    <>
      {popupVisible && !getCookieAccept && (
        <div
          className={cn(
            'cookies-popup',

            { 'cookies-popup_active': detailsVisible },
            [className]
          )}
        >
          <div className="cookies-popup-container">
            <div className="cookies-popup__body">
              <div className="cookies-popup__body__content">
                <Text className="cookies-popup__body__content__title body-sm_bold">
                  {popup.title}
                </Text>
                <Text className="cookies-popup__body__content__description">
                  {popup.description}
                </Text>
                <Link
                  className="cookies-popup__body__content__link"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    hidePopup();
                  }}
                  isRouter={false}
                >
                  <Text
                    tag="span"
                    className="cookies-popup__body__content__description body-sm"
                  >
                    {popup.buttons.accept_only_required_button_text}
                  </Text>
                </Link>
              </div>
              <div className="cookies-popup__body__action">
                <Button
                  onClick={hidePopup}
                  className="cookies-popup__body__action__accept"
                  title={popup.buttons.accept_all_button_text}
                />

                <Button
                  onClick={showDetails}
                  className="cookies-popup__body__action__details "
                  title={
                    !detailsVisible
                      ? popup.buttons.show_details_button_text
                      : popup.buttons.hide_details_button_text
                  }
                  variant="inline"
                />
              </div>
            </div>

            {detailsVisible && (
              <div className="cookies-popup__details">
                <TabBar className="cookies-popup__details__tab-bar">
                  {popup.tabs.map(
                    ({ title, content_type, content, subtabs }, tab_index) => {
                      if (content_type === 'tabs') {
                        return (
                          <TabBarItem
                            key={tab_index}
                            className="cookies-popup__details__tab-bar__item"
                            label={title}
                          >
                            <TabBar
                              vertical={true}
                              className="cookies-popup__details__tab-bar_vertical"
                            >
                              {subtabs.map(
                                (
                                  {
                                    title: subtab_title,
                                    content: subtab_content,
                                  },
                                  subtab_index
                                ) => (
                                  <TabBarItem
                                    key={[tab_index, subtab_index].join('')}
                                    className="cookies-popup__details__tab-bar__item_vertical"
                                    label={subtab_title}
                                    dangerouslySetInnerHTML={{
                                      __html: subtab_content,
                                    }}
                                  />
                                )
                              )}
                            </TabBar>
                          </TabBarItem>
                        );
                      } else {
                        return (
                          <TabBarItem
                            key={tab_index}
                            className="cookies-popup__details__tab-bar__item"
                            label={title}
                            dangerouslySetInnerHTML={{ __html: content }}
                          />
                        );
                      }
                    }
                  )}
                </TabBar>

                <div className="cookies-popup__details__footer">
                  <Text className="cookies-popup__details__footer__text">
                    {popup.footer_text}
                  </Text>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

CookiesPopup.defaultProps = {
  className: '',
};

CookiesPopup.propTypes = {
  className: PropTypes.string,
};

export default CookiesPopup;
